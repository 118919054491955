import { FC } from 'react'
import classNames from 'classnames'

const themes = {
  danger: 'bg-danger text-danger bg-opacity-10',
  info: 'bg-info text-info bg-opacity-10',
  primary: 'bg-primary text-primary bg-opacity-10',
  secondary: 'bg-secondary text-secondary bg-opacity-10',
  success: 'bg-success text-success bg-opacity-10',
  warning: 'bg-warning text-warning bg-opacity-10',
}

const sizes = {
  counter: 'badge--counter',
  default: '',
  dot: 'badge--dot',
}

type BadgeType = {
  size?: keyof typeof sizes
  className?: string
  theme?: keyof typeof themes
}

const Badge: FC<BadgeType> = ({
  children,
  className,
  size,
  theme = 'primary',
}) => {
  const themeClass = themes[theme]
  const sizeClass = size && sizes[size]

  return (
    <div
      className={classNames(
        'badge d-flex fs-xs text-nowrap align-items-center justify-content-center text-center fs-xs',
        themeClass,
        sizeClass,
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Badge
