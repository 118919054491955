import { FC } from 'react'
import { useGetStatsQuery } from 'common/services/useStat'
import classNames from 'classnames'
import Badge from 'components/base/Badge'

type StatsType = {}

const Stats: FC<StatsType> = ({}) => {
  const { data, isLoading } = useGetStatsQuery({})
  const fallback = isLoading ? '' : '0'
  const calculatePercentageChange = (current: number, previous: number) => {
    if (previous === 0 && current !== 0) return current * 100
    if (previous !== 0 && current === 0) return current * -100
    if (previous === 0) return 0
    return ((current - previous) / previous) * 100
  }
  const items = [
    {
      description: `API ${
        data?.active_subscriptions?.api || fallback
      } | Campaign ${data?.active_subscriptions?.campaign || fallback}`,
      percentage_change: null,
      title: 'Active Subscriptions',
      value: data?.active_subscriptions?.total || fallback, // No percentage change calculation for this item
    },
    {
      description: `Last month ${data?.sign_ups?.last_month || fallback}`,
      percentage_change: calculatePercentageChange(
        data?.sign_ups?.this_month || 0,
        data?.sign_ups?.last_month || 0,
      ),
      title: 'Signups this month',
      value: data?.sign_ups?.this_month || fallback,
    },
    {
      description: `Yesterday ${data?.sign_ups?.yesterday || fallback}`,
      percentage_change: calculatePercentageChange(
        data?.sign_ups?.today || 0,
        data?.sign_ups?.yesterday || 0,
      ),
      title: 'Signups today',
      value: data?.sign_ups?.today || fallback,
    },
  ]

  return (
    <div
      className={classNames(
        { 'opacity-50': isLoading },
        'd-flex flex-column flex-md-row gap-4',
      )}
    >
      {items.map((v, i) => (
        <div
          key={i}
          className='flex-1 px-2 py-4 bg-white d-flex align-items-center px-4 rounded shadow-2'
        >
          <div className='flex-1 flex-column'>
            <h3 className='d-flex mt-2 fw-semibold gap-2 align-items-center'>
              {v.title}
            </h3>
            <div className='d-flex align-items-center mb-2'>
              <h5 className='text-muted mb-0 fw-semibold'>{v.description}</h5>

              {v.percentage_change !== null && (
                <Badge
                  className='ms-4'
                  theme={
                    !v.percentage_change
                      ? 'info'
                      : v.percentage_change > 0
                      ? 'success'
                      : 'danger'
                  }
                >
                  <span className='fa fa-line-chart me-1' />{' '}
                  {v.percentage_change ? (
                    <>
                      {v.percentage_change > 0 && '+'}
                      {v.percentage_change.toFixed(0)}%
                    </>
                  ) : (
                    'no change'
                  )}
                </Badge>
              )}
            </div>
          </div>
          <h1 className='mb-0' style={{ fontSize: 32 }}>
            {v.value}
          </h1>
        </div>
      ))}
    </div>
  )
}

export default Stats
