import { FC, useState } from 'react'
import { enumeration } from 'common/utils/string/enumeration'
import TextDropdown from './TextDropdown'
import DropdownItem from './forms/DropdownItem'

type SelectDropdownType = {
  options: any[]
  value?: any[]
  title: string
  onChange: (v: string[]) => void
}

const SelectDropdown: FC<SelectDropdownType> = ({
  onChange,
  options,
  title,
  value,
}) => {
  const [show, setShow] = useState(false)

  return (
    <TextDropdown
      show={show}
      setShow={setShow}
      dropdownContent={options?.map((v, i) => {
        const selected = value?.includes(v)
        return (
          <DropdownItem
            onClick={() => {
              if (selected) {
                onChange((value || []).filter((item) => v !== item))
              } else {
                onChange((value || []).concat([v]))
              }
              setShow(false)
            }}
            key={i}
            selected={selected}
          >
            {enumeration.get(v)}
          </DropdownItem>
        )
      })}
    >
      {title}
    </TextDropdown>
  )
}

export default SelectDropdown
