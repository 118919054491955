import { FC, useState } from 'react'
import InputGroup from './base/forms/InputGroup'
import Select from './base/forms/Select'
import { ErrorMessage } from './base/Messages'
import Button from './base/forms/Button'
import ModalDefault from './base/ModalDefault'
import { toast } from './base/Toast'
import { useCreateCancelSubscriptionMutation } from 'common/services/useCancelSubscription'

type CancelSubscriptionType = {
  isOpen: boolean
  customerId: number
  id: number
  setIsOpen: (value: boolean) => void
  onCancel?: () => void
}

const CancelSubscription: FC<CancelSubscriptionType> = ({
  customerId,
  id,
  isOpen,
  onCancel,
  setIsOpen,
}) => {
  const [immediateCancel, setImmediateCancel] = useState(true)
  const [cancelSubscription, { error: cancelError, isLoading: cancelLoading }] =
    useCreateCancelSubscriptionMutation({})

  const doCancel = () => {
    cancelSubscription({
      company_id: customerId!,
      id: id,
      reason: 'Requested',
      termination_type: immediateCancel ? 'IMMEDIATE' : 'END_OF_TERM',
    }).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setIsOpen(false)
        toast('Success', 'Cancelled Customer Subscription')
        onCancel?.()
      }
    })
  }
  return (
    <ModalDefault
      title={'Cancel Subscription'}
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      toggle={() => setIsOpen(false)}
    >
      <InputGroup
        title='When do you want to cancel the subscription?'
        component={
          <Select
            onChange={(v: any) => setImmediateCancel(v.value)}
            value={
              immediateCancel
                ? { label: 'Immediately', value: true }
                : { label: 'End of Term', value: false }
            }
            options={[
              { label: 'End of Term', value: false },
              { label: 'Immediately', value: true },
            ]}
          />
        }
      />
      <ErrorMessage className='mt-2'>{cancelError}</ErrorMessage>
      <div className='text-end mt-4'>
        <Button
          onClick={() => {
            setIsOpen(false)
          }}
          className='me-2'
          theme='secondary'
        >
          Back
        </Button>
        <Button
          onClick={doCancel}
          disabled={cancelLoading}
          className='me-2'
          theme='danger'
        >
          Confirm Cancellation
        </Button>
      </div>
    </ModalDefault>
  )
}

export default CancelSubscription
