import { FC, ReactNode } from 'react'
import FilterInput, { FilterInputType } from './FilterInput'
import SelectDropdown from 'components/base/SelectDropdown'
import { hi } from 'date-fns/locale'

type TableFilterType = {
  filters: FilterInputType['filters']
  onChange: FilterInputType['onChange']
  setPage?: (newPage: number) => void
  value: string
  customFilters?: ReactNode
  hideInput?: boolean
  placeholder?: string
}

const TableFilter: FC<TableFilterType> = ({
  children,
  customFilters,
  filters,
  hideInput,
  onChange,
  placeholder,
  setPage,
  value,
}) => {
  return (
    <div className='py-4 px-1 my-1 align-items-center d-flex'>
      <div className='body-secondary'>Filter</div>
      <div className='ps-8 align-items-center d-flex'>
        {filters.map((v) => (
          <div key={v.label} className='me-4'>
            <SelectDropdown
              options={v.options}
              title={v.label}
              value={v.value}
              onChange={(newValue) => {
                setPage?.(1)
                v.onChange(newValue)
              }}
            />
          </div>
        ))}
        {customFilters}
      </div>
      <div className='flex-fill'>
        <div className='w-100' style={{ maxWidth: 700 }}>
          {!hideInput && (
            <FilterInput
              placeholder={placeholder || 'Search...'}
              filters={filters}
              setPage={setPage}
              value={value}
              onChange={(v) => {
                setPage?.(1)
                onChange(v)
              }}
            />
          )}
        </div>
      </div>
      {children}
    </div>
  )
}

export default TableFilter
