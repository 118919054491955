import React, { FC } from 'react'
import planCost from 'common/utils/project/planCost'
import { useUser } from 'common/services/useUser' // we need this to make JSX compile

type ProductNameType = {
  id: number
}

const PlanName: FC<ProductNameType> = ({ id }) => {
  const { data } = useUser({})
  const matchingItem = data?.products?.find((v) => `${v.id}` === `${id}`)

  return matchingItem ? (
    <div className='d-flex flex-column'>
      <div className='fw-bold'>{matchingItem.name}</div>
      {planCost(matchingItem)}
    </div>
  ) : null
}

export default PlanName
