import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { getStore } from 'common/store'
import { subscriptionService } from './useSubscription'

export const cancelSubscriptionService = service
  .enhanceEndpoints({ addTagTypes: ['CancelSubscription'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createCancelSubscription: builder.mutation<
        Res['cancelSubscription'],
        Req['createCancelSubscription']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'CancelSubscription' }],
        query: (query: Req['createCancelSubscription']) => ({
          body: query,
          method: 'POST',
          url: `partners/company/${query.company_id}/subscriptions/${query.id}/cancel`,
        }),
        transformResponse: (res) => {
          getStore().dispatch(
            subscriptionService.util.invalidateTags(['Subscription']),
          )

          return res
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createCancelSubscription(
  store: any,
  data: Req['createCancelSubscription'],
  options?: Parameters<
    typeof cancelSubscriptionService.endpoints.createCancelSubscription.initiate
  >[1],
) {
  return store.dispatch(
    cancelSubscriptionService.endpoints.createCancelSubscription.initiate(
      data,
      options,
    ),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateCancelSubscriptionMutation,
  // END OF EXPORTS
} = cancelSubscriptionService

/* Usage examples:
const { data, isLoading } = useGetCancelSubscriptionQuery({ id: 2 }, {}) //get hook
const [createCancelSubscription, { isLoading, data, isSuccess }] = useCreateCancelSubscriptionMutation() //create hook
cancelSubscriptionService.endpoints.getCancelSubscription.select({id: 2})(store.getState()) //access data from any function
*/
