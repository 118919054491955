import { FC } from 'react'
import { PagedResponse } from 'common/types/responses'
import _range from 'lodash/range'
import classNames from 'classnames'

type PaginationType = {
  data: PagedResponse<any>
  onChange: (newPage: number) => void
}

const Ellips = () => <span className='text-muted px-2'>...</span>

const Pagination: FC<PaginationType> = ({ data, onChange }) => {
  if (!data || data?.totalPages < 2) {
    return null
  }
  const currentIndex = data.currentPage
  const lastPage = data.totalPages
  const spaceBetween = 2
  // const numberOfPages = Math.ceil(paging.count / paging.pageSize);
  const from = Math.max(1, currentIndex - spaceBetween)
  const to = Math.min(lastPage + 1, currentIndex + 1 + spaceBetween)
  const range = _range(from, to)

  return (
    <div className='row mt-4'>
      <div className='col-lg-4 d-none d-lg-block' />
      <div className='col-lg-4 col-md-8'>
        <div className='flex-row align-items-center justify-content-center'>
          <button
            disabled={data.currentPage === 1}
            className={'btn btn-secondary btn-pagination  mx-4 px-2'}
            onClick={() => onChange(data.currentPage - 1)}
          >
            <span className='fa fa-chevron-left' />
          </button>
          {from > 1 && (
            <>
              <button
                onClick={() => onChange(1)}
                className={'btn btn-secondary btn-pagination  mx-1 px-2'}
              >
                1
              </button>
              {from - 1 > 1 && (
                <span className='fa px-2 text-muted fa-ellipsis' />
              )}
            </>
          )}
          {range.map((v) => (
            <button
              key={v}
              onClick={() => onChange(v)}
              className={classNames(
                'btn btn-pagination  mx-1 px-2',
                {
                  'btn-pagination--active btn-primary': v === currentIndex,
                },
                { 'btn-secondary': v !== currentIndex },
              )}
            >
              {v}
            </button>
          ))}
          {to - 1 < lastPage && (
            <>
              {to < lastPage && (
                <span className='fa px-2 text-muted fa-ellipsis' />
              )}
              <button
                onClick={() => onChange(lastPage)}
                className={'btn btn-secondary btn-pagination  mx-1 px-2'}
              >
                {lastPage}
              </button>
            </>
          )}
          <button
            disabled={data.currentPage === lastPage}
            className={'btn btn-secondary btn-pagination  mx-4 px-2'}
            onClick={() => onChange(data.currentPage + 1)}
          >
            <span className='fa fa-chevron-right fs-xs' />
          </button>
        </div>
      </div>
      <div className='col-4'></div>
    </div>
  )
}

export default Pagination
