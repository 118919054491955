import { FC } from 'react'
import { PagedResponse } from 'common/types/responses'

type NoResultsMessageType = {
  data: PagedResponse<any> | undefined | any[]
}

const NoResultsMessage: FC<NoResultsMessageType> = ({ data }) => {
  //@ts-ignore
  return !data?.results?.length && !data?.length ? (
    <tr>
      <td className='text-center py-3' colSpan={99}>
        No results.
      </td>
    </tr>
  ) : null
}

export default NoResultsMessage
