import { BillingPeriod, PartnerProduct } from 'common/types/responses'
import money from 'common/utils/number/money'

export default function (product: PartnerProduct) {
  if (!product) {
    return null
  }
  return `${money(product.cost)} per ${
    product.billing_period === BillingPeriod.MONTHLY ? 'month' : 'year'
  }`
}
