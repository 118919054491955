import React, { FC, useEffect, useState } from 'react'
import Input, { InputType } from 'components/base/forms/Input'
import Dropdown from 'components/base/forms/Dropdown'
import Calendar from './Calendar'
import { CalendarProps } from 'react-calendar'
import dayjs from 'dayjs'
import { safeParseEventValue } from 'common/utils/forms/safeEventParseValue'
import Button from 'components/base/forms/Button'
import classNames from 'classnames'
import Icon from 'project/Icon'

type DateRangePickerProps = Omit<CalendarProps, 'onChange' | 'value'> & {
  placeholder?: string
  format?: string
  inputProps?: InputType
  onChange: (v: string[] | null) => void
  value: string[] | null
  onActiveStartDateChange?: (date: Date) => void
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  format = 'DD/MM/YYYY',
  inputProps,
  onActiveStartDateChange,
  placeholder,
  ...calendarProps
}) => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [focused, setFocused] = useState(false)
  const [activeStartDate, setActiveStartDate] = useState<Date | undefined>(
    calendarProps.value && calendarProps.value[0]
      ? new Date(calendarProps.value[0])
      : undefined,
  )

  useEffect(() => {
    if (!focused && calendarProps.value) {
      const newValue = Array.isArray(calendarProps.value)
        ? `${dayjs(calendarProps.value[0] as any).format(format)} - ${dayjs(
            calendarProps.value[1] as any,
          ).format(format)}`
        : ''
      if (newValue !== inputValue) {
        setInputValue(newValue)
      }
    }
  }, [calendarProps.value, focused])

  useEffect(() => {
    if (calendarProps.value && calendarProps.value[0]) {
      const newStartDate = new Date(calendarProps.value[0])
      if (newStartDate !== activeStartDate) {
        setActiveStartDate(newStartDate)
        if (onActiveStartDateChange) {
          onActiveStartDateChange(newStartDate)
        }
      }
    } else if (!calendarProps.value) {
      setInputValue('')
      setActiveStartDate(null)
    }
  }, [calendarProps.value])
  console.log(calendarProps)
  return (
    <div style={{ width: 220 }} className='d-flex align-items-center'>
      <div className='flex-1'>
        <Dropdown
          show={showCalendar}
          setShow={setShowCalendar}
          dropdownClassName='px-0 py-0 mt-0'
          dropdownContent={
            <Calendar
              selectRange
              showDoubleView
              activeStartDate={activeStartDate}
              onActiveStartDateChange={({ activeStartDate }) => {
                setActiveStartDate(activeStartDate)
              }}
              {...calendarProps}
              onChange={(v: Date[] | null) => {
                if (!v) {
                  calendarProps.onChange(null)
                } else {
                  calendarProps.onChange([
                    (v[0] as Date).toISOString(),
                    (v[1] as Date).toISOString(),
                  ])
                }
                if (v && v[0] && v[0] !== activeStartDate) {
                  setActiveStartDate(new Date(v[0]))
                  if (onActiveStartDateChange) {
                    onActiveStartDateChange(new Date(v[0]))
                  }
                }
              }}
            />
          }
        >
          <Input
            onChange={(e) => {
              const str = safeParseEventValue(e)
              setInputValue(str)
              const dates = str
                .split(' - ')
                .map((date: string) => dayjs(date, format))
              if (
                dates.length === 2 &&
                dates[0].isValid() &&
                dates[1].isValid()
              ) {
                calendarProps.onChange([
                  dates[0].toISOString(),
                  dates[1].toISOString(),
                ])
              }
            }}
            placeholder={placeholder}
            onFocus={() => {
              setFocused(true)
              setShowCalendar(true)
            }}
            onBlur={() => {
              setFocused(false)
            }}
            value={inputValue}
            onIconRightClick={
              calendarProps.value
                ? () => {
                    calendarProps.onChange(null)
                  }
                : undefined
            }
            iconRight={
              calendarProps.value ? (
                <div className='cursor-pointer'>
                  <span className='fa fa-times-circle fs-lg' />
                </div>
              ) : (
                <Icon name='calendar' />
              )
            }
            {...(inputProps || {})}
          />
        </Dropdown>
      </div>
    </div>
  )
}

export default DateRangePicker
