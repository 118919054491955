export default function money(value: number) {
  if (value) {
    return value?.toLocaleString('en-GB', {
      currency: 'GBP',
      style: 'currency',
    })
  }
  return (0).toLocaleString('en-GB', {
    currency: 'GBP',
    style: 'currency',
  })
}
