import React, { FC, FormEventHandler, ReactNode } from 'react'
import TheInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip'
import { enumeration } from 'common/utils/string/enumeration'
import { flatten } from 'lodash'
import { safeParseEventValue } from 'common/utils/forms/safeEventParseValue'
import classNames from 'classnames'
import useSearchThrottle from 'common/hooks/useSearchThrottle'

export type FilterInputType = {
  placeholder?: string
  disabled?: boolean
  onClear?: () => void
  filters: {
    label: string
    searchFilter?: boolean
    options: any[]
    singleSelect?: boolean
    value: string[]
    renderOption?: (v: string) => ReactNode
    onChange: (v: any[]) => void
  }[]
  setPage?: (newPage: number) => void

  value?: string
  onChange: (value: string) => void
}

const FilterInput: FC<FilterInputType> = ({
  disabled,
  filters,
  onChange,
  onClear,
  placeholder,
  setPage,
  value,
}) => {
  const { searchInput, setSearchInput } = useSearchThrottle(
    value || '',
    onChange,
  )
  const onChangeText: FormEventHandler = (e) => {
    setSearchInput(safeParseEventValue(e as any))
  }
  const filterValue = flatten(
    filters.map((v) =>
      v.value.map((item) => ({
        onDelete: () => {
          setPage?.(1)
          v.onChange(v.value.filter((value) => value !== item))
        },
        renderOption: v.renderOption,
        value: item,
      })),
    ),
  )
  return (
    <div
      className={classNames('position-relative input-container', {
        'input-container--with-right-icon': !!filterValue?.length,
      })}
    >
      <TheInput
        disabled={disabled}
        fullWidth
        placeholder={placeholder}
        disableUnderline
        blurBehavior='ignore'
        onInput={onChangeText}
        newChipKeys={[]}
        newChipKeyCodes={[]}
        inputValue={searchInput}
        value={filterValue}
        onDelete={(_, index: number) => {
          filterValue?.[index]?.onDelete?.()
        }}
        classes={{
          chip: 'chip',
          root: 'mui-root',
        }}
        chipRenderer={({ className, handleClick, value }: any, key: any) => {
          return (
            <Chip
              key={key}
              className={`${className} badge badge-secondary`}
              onClick={handleClick}
              onDelete={value.onDelete}
              label={
                value.renderOption
                  ? value.renderOption(value.value)
                  : enumeration.get(value.value)
              }
              deleteIcon={
                disabled ? <span /> : <span className='chip-icon fa fa-times' />
              }
            />
          )
        }}
      />
      {!!filterValue?.length && (
        <div
          onClick={() => {
            if (onClear) {
              onClear()
              return
            }
            filters.map((v) => {
              setPage?.(1)
              v.onChange([])
            })
          }}
          className='cursor-pointer input-icon icon-right'
        >
          <span className='fa fa-times-circle' />
        </div>
      )}
    </div>
  )
}

export default FilterInput
