import { FC, useEffect, useState } from 'react'
import _Select, { components, Props } from 'react-select'
import SelectIcon from 'components/base/SelectIcon'
import classNames from 'classnames'

export type SelectType = Props & {
  isValid?: boolean
  shouldValidate?: boolean
}

const Control = ({ children, ...props }: any) => (
  <components.Control className='select-form-control' {...props}>
    {children}
  </components.Control>
)
const Select: FC<SelectType> = ({
  isValid,
  shouldValidate: _shouldValidate,
  ...rest
}) => {
  const [shouldValidate, setShouldValidate] = useState(false)
  useEffect(() => {
    if (_shouldValidate) {
      setShouldValidate(true)
    }
  }, [_shouldValidate])
  return (
    <div
      className={classNames(
        'position-relative input-container',
        rest.className,
        {
          invalid: isValid === false && !!shouldValidate,
        },
      )}
    >
      <_Select
        onBlur={() => {
          setShouldValidate(true)
        }}
        components={{
          Control,
          DropdownIndicator: SelectIcon,
        }}
        {...rest}
        classNamePrefix={'select'}
        className='select'
      />
    </div>
  )
}

export default Select
