import { FC, useState } from 'react'
import Dropdown, { DropdownType } from './forms/Dropdown'
import SelectIcon from './SelectIcon'

type TextDropdownType = DropdownType

const TextDropdown: FC<TextDropdownType> = ({ children, ...props }) => {
  return (
    <Dropdown {...props}>
      <div
        onClick={() => props.setShow(!props.show)}
        className='flex-row cursor-pointer h-100 d-flex align-items-center'
      >
        <div className='me-2 fw-semibold user-select-none'>{children}</div>
        <SelectIcon />
      </div>
    </Dropdown>
  )
}

export default TextDropdown
