import { FC } from 'react'
import {
  CompanySubscriptionDetail,
  CompanySubscriptionSummary,
} from 'common/types/responses'
import Badge from 'components/base/Badge'
import { enumeration } from 'common/utils/string/enumeration'

type SubscriptionStatusType = {
  subscription: CompanySubscriptionDetail | CompanySubscriptionSummary
  className?: string
}

const SubscriptionStatus: FC<SubscriptionStatusType> = ({
  className,
  subscription,
}) => {
  if (!subscription) return null
  return (
    <div className='d-flex'>
      <div>
        <Badge
          className={className}
          theme={subscription.status === 'ACTIVE' ? 'success' : 'danger'}
        >
          {enumeration.get(subscription.status)}
        </Badge>
      </div>
    </div>
  )
}

export default SubscriptionStatus
