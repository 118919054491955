import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { responses } from 'common/types/mock-data'

export const statService = service
  .enhanceEndpoints({ addTagTypes: ['Stat'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getStats: builder.query<Res['stats'], Req['getStats']>({
        providesTags: [{ id: 'LIST', type: 'Stat' }],
        query: () => ({
          url: `partners/statistics`,
        }),
        // queryFn: () => ({ data: responses.stats }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getStats(
  store: any,
  data: Req['getStats'],
  options?: Parameters<typeof statService.endpoints.getStats.initiate>[1],
) {
  return store.dispatch(statService.endpoints.getStats.initiate(data, options))
}
// END OF FUNCTION_EXPORTS

export const {
  useGetStatsQuery,
  // END OF EXPORTS
} = statService

/* Usage examples:
const { data, isLoading } = useGetStatsQuery({ id: 2 }, {}) //get hook
const [createStats, { isLoading, data, isSuccess }] = useCreateStatsMutation() //create hook
statService.endpoints.getStats.select({id: 2})(store.getState()) //access data from any function
*/
