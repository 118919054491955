import { CSSProperties, FC, ReactNode, useState } from 'react'
import Button from './forms/Button'
import Dropdown from './forms/Dropdown'

type EllipsisDropdownType = {
  show?: boolean
  setShow?: (show: boolean) => void
  ellipsisIcon?: ReactNode
  style?: CSSProperties
}

const dropdownIcon = <i className='fa fal fa-chevron-down' />
const buttonClass = 'hover-bg-light300'

const EllipsisDropdown: FC<EllipsisDropdownType> = ({
  children,
  ellipsisIcon = (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 4C11.175 4 10.5 4.675 10.5 5.5C10.5 6.325 11.175 7 12 7C12.825 7 13.5 6.325 13.5 5.5C13.5 4.675 12.825 4 12 4ZM12 17C11.175 17 10.5 17.675 10.5 18.5C10.5 19.325 11.175 20 12 20C12.825 20 13.5 19.325 13.5 18.5C13.5 17.675 12.825 17 12 17ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z'
        fill='#1D1D1F'
      />
    </svg>
  ),
  setShow,
  show: controlledShow,
  style,
}) => {
  // State to manage the show state internally if setShow is not provided
  const [internalShow, setInternalShow] = useState(false)

  // Determine if we are using controlled or internal state
  const isControlled = setShow !== undefined
  const show = isControlled ? controlledShow : internalShow

  const handleSetShow = (value: boolean) => {
    if (isControlled) {
      setShow?.(value)
    } else {
      setInternalShow(value)
    }
  }

  return (
    <>
      <Dropdown
        position='right'
        dropdownClassName='ms-4'
        show={show}
        setShow={handleSetShow}
        dropdownContent={children}
        icon={dropdownIcon}
        style={style}
      >
        <Button
          className={buttonClass}
          size='extra-small'
          onClick={(e) => {
            e?.stopPropagation()
            handleSetShow(!show)
          }}
          theme='text'
          icon
        >
          {ellipsisIcon}
        </Button>
      </Dropdown>
    </>
  )
}

export default EllipsisDropdown
