import { camelCase } from './camelCase'

export const enumeration = {
  get(value: string | null | undefined): string {
    // MY_CONSTANT > My constant
    if (!value) {
      return ''
    }
    return camelCase(value.replace(/_/g, ' '))
  },
  set(value: string): string {
    // My Constant > MY_CONSTANT
    return value ? value.replace(/ /g, '_').toUpperCase() : ''
  },
}
